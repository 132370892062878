// 颜色定义规范
$color-theme               : #3474ff;
$color-theme-background    : #3474ff;

$color-blue                : #0084ff;
$color-cfe                 : #00cfe7;
$color-dfe                 : #536dfe;
$color-bff                 : #4b84ff;
$color-35c                 : #355cea;
$color-4bf                 : #4b93ff;
$color-3de                 : #355dea;
$color-17e                 : #171e31;
$color-0c1                 : #0c1030;
$color-c3c                 : #c3cde6;
$color-f6f                 : #f6f6f6;
$color-b3b                 : #b3b3b3;
$color-b5b                 : #b5b5b5;
$color-d4d                 : #d4d4d4;
$color-040                 : #040000;

$color-base                : #fff;
$color-cc                  : #cccccc;
$color-base-background     : #fff;
$color-light-background    : #f2f2f2;
$color-background-e        : #eee;
$color-background-ds       : rgba(0, 0, 0, 0.1);
$color-background-dm       : rgba(0, 0, 0, 0.2);
$color-background-dd       : rgba(0, 0, 0, 0.3);
$color-background-dx       : rgba(0, 0, 0, 0.5);
$color-background-checked  : #e1ebf5;  // 被选中的背景颜色
$color-theme: #3474ff;
$color-theme-background: #3474ff;
$color-blue: #0084ff;
$color-cfe: #00cfe7;
$color-dfe: #536dfe;
$color-bff: #4b84ff;
$color-35c: #355cea;
$color-4bf: #4b93ff;
$color-3de: #355dea;
$color-17e: #171e31;
$color-c3c: #c3cde6;
$color-f6f: #f6f6f6;
$color-b3b: #b3b3b3;
$color-b5b: #b5b5b5;
$color-d4d: #d4d4d4;
$color-040: #040000;
$color-base: #fff;
$color-cc: #cccccc;
$color-base-background: #fff;
$color-light-background: #f2f2f2;
$color-background-e: #eee;
$color-background-ds: rgba(0, 0, 0, 0.1);
$color-background-dm: rgba(0, 0, 0, 0.2);
$color-background-dd: rgba(0, 0, 0, 0.3);
$color-background-dx: rgba(0, 0, 0, 0.5);
$color-background-checked: #e1ebf5; // 被选中的背景颜色
$color-highlight-background: #333;
$color-text: #333; // 基础默认字体颜色
$color-text-m: #666;
$color-text-g: #999;
$color-text-inp: #a5a5a5;
$color-text-tit: #FFFFFF;
$color-black: #000;
$color-black-d: #303030;
$color-error-text: #f5222d;
$color-red: #cc3500;
$color-warn: #ca1d00;
// 线的颜色
$color-border-e: #e5e5e5;
$color-border-ee: #eeeeee;
$color-border-d: #d2d2d2;
$color-border: #e8e8e8;
$color-border-c: #c6c9d0;
//字体定义规范
$font-size-small-s: 10px;
$font-size-small-sm: 11px;
$font-size-small: 12px;
$font-size-small-x: 13px;
$font-size-medium: 14px;
$font-size-base: 14px; // 默认基础14px
$font-size-medium-s: 15px;
$font-size-medium-x: 16px;
$font-size-large: 17px;
$font-size-large-s: 18px;
$font-size-large-x: 20px;
$font-size-large-xa: 22px;
$font-size-large-xb: 24px;
$font-size-large-xs: 30px;
$font-size-large-xm: 48px;
$font-size-large-xl: 70px;
$font-size-large-xg: 80px;
// 定义去掉头部和底部的视口高度
// $view-height       : calc(100vh - 95px);
// 定义宽高
$content-width: 1200px; //calc(100vw - 160px);
$nav-height: 70px;
$home-btn-width: 180px;
$home-btn-height: 60px;
$home-contact-ccustomer-height: 160px;
$footer-height                : 280px;
$footer-content-width         : 1047px; //calc(100vw - 160px); 

// 定义圆角
$border-radius-base: 2px;
$border-radius-circular: 50%; // 圆形
// 定义字间距letter-spacing
$letter-spacing-s: 2px;
// 定义font-family
$font-family-YaHei-bold: "MicrosoftYaHei-Bold";
$font-family-YaHei: "MicrosoftYaHei"; // 基础默认
$font-family-SSL: "SourceHanSansCN-Light";
$font-family-PZD: "PangMenZhengDao";
$font-family-REG: "SourceHanSansCN-Regular";
$font-family-NOR: "SourceHanSansCN-Normal";
// 定义行高
$line-height-default: 1; // 基础默认
$line-height-m: 22px;
$line-height-x: 25px;
$line-height-g: 60px;
// 定义边距 margin
$margin-i: 5px;
$margin-s: 10px;
$margin-ss: 15px;
$margin-sm: 20px;
$margin-sn: 25px;
$margin-medium: 30px;
$margin-medium-s: 40px;
$margin-medium-x: 50px;
$margin-large: 60px;
$margin-large-s: 70px;
$margin-large-x: 80px;
$margin-large-l: 90px;
$margin-large-g: 100px;
$margin-large-gx: 110px;
$margin-large-gl: 120px;
// 定义边距 padding
$padding-i: 5px;
$padding-s: 10px;
$padding-ss: 15px;
$padding-sm: 20px;
$padding-sn: 25px;
$padding-medium: 30px;
$padding-medium-s: 40px;
$padding-medium-x: 50px;
$padding-large: 60px;
$padding-large-s: 70px;
$padding-large-x: 80px;
$padding-large-l: 90px;
$padding-large-g: 100px;
$padding-large-gx: 110px;
$padding-large-gl: 120px;