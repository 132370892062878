@import "../../common/styles/variable-mixin/index.scss";
.bri_container{
    width: 100%;
    box-sizing: border-box;
    padding: 51px 0 178px;
    display: flex;
    font-family: $font-family-YaHei;
    font-size: 18px;

    .con_left{
        display: flex;

        span{
            width: 131px;
            font-size: 32px;
            font-weight: bold;
            color: #394754;
            margin: 0 70px 0 10px;
        }
    }

    .con_right{
        width: 100%;
        height: 163px;
        overflow: auto; /* 超出高度出现滚动条 */

        p{
            width: 100%; /* 宽度100% */
            word-wrap: break-word; /* 达到宽度换行 */
            white-space: pre-line; /* 保留换行符号 */
            line-height: 30px;
            color: #44494f;

            span{
                font-weight: bold;
            }
        }
    }
}