@import "../../common/styles/variable-mixin/index.scss";

.team_container {
    width: 100%;
    box-sizing: border-box;
    padding: 51px 0 92px;
    display: flex;
    font-family: $font-family-REG;
    font-size: 12px;
    color: #4d4d4d;

    .con_left {
        display: flex;

        span {
            width: 131px;
            font-size: 32px;
            font-weight: bold;
            color: #394754;
            margin: 0 71px 0 10px;
        }
    }

    .con_right {
        width: 100%;
        @include column;

        & .ditem:first-child {
            margin-bottom: 60px;
        }

        .ditem {
            display: flex;
            flex-direction: column;
        }

        .xitem {
            display: flex;
            margin-bottom: 40px;

            img {
                margin-right: 50px;
            }

            // justify-content: space-between;

            // width: 400px;
            // &:first-child {
            //     margin-right: 150px;
            // }



            .desc {
                flex: 1;
                justify-content: center;
                @include column;
                // height: 90px;

                .desc_title {
                    @include ellipsis;

                    & span:first-child {
                        font-size: 16px;
                        margin-right: 28px;

                        font-family: MicrosoftYaHei;
                        font-weight: bold;
                        color: #000010;
                    }

                    margin-bottom: 14px;
                }

                .descp {
                    width: 100%;
                    overflow: auto;
                    /* 超出高度出现滚动条 */
                    color: #808080;

                    p {
                        width: 100%;
                        /* 宽度100% */
                        word-wrap: break-word;
                        /* 达到宽度换行 */
                        white-space: pre-line;
                        /* 保留换行符号 */
                        line-height: 20px;
                    }
                }
            }
        }
    }
}