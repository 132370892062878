@import './variable-mixin/index.scss';

html {
    @include container;
}

body {
    font-family: "Microsoft Yahei", Arial, "Helvetica Neue", Helvetica, sans-serif;
    /*
        基础默认line-height: 1;
    */
	line-height: $line-height-default;
	color: $color-text;
    font-size: $font-size-base;
    @include container;
}
a{
	color: $color-theme;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
    /* 清除a标签按下后变色 */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance: none;
}

/* 全局添加a链接按下的样式 */
a:active,.a-active {
    opacity: .8;
}

#root, .app, .layout{
    @include container;
}
.layout{
    @include column;
}
.layout-main{
    flex: 1;
    @include scroll;
}

/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0);
}
/*定义滚动条的轨道，内阴影及圆角*/
*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(230, 230, 230, 0.05);
}
*:hover::-webkit-scrollbar-track {
    background-color: rgba(230, 230, 230, 0.5);
}
/*定义滑块，内阴影及圆角*/
*::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background-color: rgba(216, 216, 216, 0.4);
    transition: background-color 1s;
}
*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(216, 216, 216, 1);
}
*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(190, 190, 190, 1);
}
*::-webkit-scrollbar-thumb:active {
    background-color: rgba(160, 160, 160, 1);
}

// 定义input输入框placeholder的样式
::-webkit-input-placeholder{
    color: $color-base;
    font-size: $font-size-base;
}
::-moz-placeholder{   /* Mozilla Firefox 19+ */
    color: $color-base;
    font-size: $font-size-base;
}
:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
    color: $color-base;
    font-size: $font-size-base;
}
:-ms-input-placeholder{  /* Internet Explorer 10-11 */
    color: $color-base;
    font-size: $font-size-base;
}
