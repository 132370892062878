// 浮动
.float-left{
    float: left;
}
.float-right{
    float:right;
}
// 清除浮动
.clearfloat:after {
    display: block;
    clear: both;
    content: "";
    visibility: hidden;
    height: 0;
    overflow: hidden;
}
.clearfloat {
    *zoom: 1;
}

.hidden{
    display: none;
}