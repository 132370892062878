@import "../../common/styles/variable-mixin/index.scss";
.evo_container{
    width: 100%;
    box-sizing: border-box;
    padding: 51px 0 92px;
    @include column;
    font-family: $font-family-YaHei;

    .con_up{
        display: flex;
       

        span{
            width: 131px;
            font-size: 32px;
            font-weight: bold;
            color: #394754;
            margin: 0 70px 0 10px;
        }
    }

    .con_bo{
        background-color: #f5f8ff;
        border-radius: 20px;
        margin-top: 42px;
    }
}