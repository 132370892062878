@import "./variable.scss";

/* 容器 */
@mixin container {
    width: 100%;
    // (PC端可设置)移动端不设置body height: 100%, 让滚动高度为body的高度的高度，滚动最流畅
    height: 100%;
    overflow-x: hidden;
}

@mixin containerContent {
    margin: 0 auto;
    width: $content-width;
}

/* flex通用布局 */
@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin columnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin columnBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin row {
    display: flex;
    flex-direction: row;
}

@mixin column {
    display: flex;
    flex-direction: column;
}

@mixin left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  
@mixin right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@mixin between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin wrapBetween {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

@mixin columnLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/* 单行不换行, 超出显示...*/
@mixin ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* 多行不换号, 超出显示..., 接收一个行数的参数 */
@mixin ellipsis2($line) {
    display: -webkit-box;
    // 指定方向： 垂直排列
    -webkit-box-orient: vertical;
    // 指定显示行数
    -webkit-line-clamp: $line;
    // 允许智能换行
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    // 表示不会打破一个单词，必须保持单词的完整才会换行
    word-break: keep-all
    // word-break: break-all;  表示单词在任何时候都会换行
    // overflow-wrap: break-word; 设置或检索当内容超过指定容器的边界时是否断行。
}

/* 定义一个滚动的样式 */
@mixin scroll {
    overflow-x: hidden;
    overflow-y: auto;
    /* 设置-webkit-overflow-scrolling: touch 可以解决移动端滚动卡顿的问题 */
    -webkit-overflow-scrolling: touch;

    /* 隐藏滚动条 --PC端不需要，移动端使用
    &::-webkit-scrollbar {
        display: none;
    }
    */
}
