@import "../../common/styles/variable-mixin/index.scss";
.cer_container{
    width: 100%;
    box-sizing: border-box;
    padding: 54px 0 110px; 
    @include columnCenter;
    font-size: 32px;
    color: #272c32;
    font-family: $font-family-YaHei-bold;
    font-weight: bold;

    .item{
        width: 100%;
        @include columnCenter;

        &:not(:first-child){
            margin-top: 60px;
        }

        .imgsdiv{
            margin-top: 50px;
            width: 100%;
            @include between;
        }

        .imgdiv{
            margin-top: 50px; 
        }
    }
}